<template>
  <div class="">
    <h2>รูปวอลเปเปอร์รออนุมัติ</h2>
    <div v-if="!loading">
      <h2 v-if="listData.length === 0" class="text-center text-muted mt-5">
        ยังไม่มีรูปที่ให้อนุมัติตอนนี้
      </h2>
      <div v-else class="mt-1 d-flex justify-content-end">
        <div v-if="!isShowMultiSelect">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm" @click="startMultiSelect">
            <feather-icon icon="FolderPlusIcon" class="mr-50" />
            <span class="align-middle">
              ส่งไปตรวจสอบหลายรูป
            </span>
          </b-button>
        </div>
        <div v-else class="d-flex" style="gap: 0.5rem;">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="success" size="sm" @click="saveMultiSelect">
            <feather-icon icon="SendIcon" class="mr-50" />
            <span class="align-middle"> บันทึก ({{ countImageForSend }}) </span>
          </b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" size="sm" @click="cancelMultiSelect">
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">
              ยกเลิก
            </span>
          </b-button>
        </div>
      </div>
      <b-row>
        <b-col sm="6" md="4" lg="3" v-for="(item, index) in listData" :key="index" class="mt-2">
          <div class="container-marker">
            <div v-if="isShowMultiSelect">
              <!-- <div class="backdrop-marker"></div> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="item.isLocalSelectForSend ? 'success' : 'secondary'"
                class="btn-icon"
                block
                @click="selectImageForSendToChecking(item)"
              >
                <feather-icon :icon="item.isLocalSelectForSend ? 'CheckIcon' : 'MinusIcon'" />
              </b-button>
            </div>
            <ImageActionCard
              :item="item"
              @onClickPreviewImage="onClickPreviewImage"
              @onClickApprove="onClickApprove"
              @onClickReject="onClickReject"
              @onClickMoreTransactionReview="onClickMoreTransactionReview"
              @onClickMoreDetailsImage="onClickMoreDetailsImage"
              :currentItemLoading="currentItemLoading"
            />
          </div>
          <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
        </b-col>
      </b-row>
    </div>
    <BoxContainerLoading :loading="loading" />

    <b-modal
      id="modal-preview-images"
      ref="modal-preview-images"
      size="xl"
      hide-footer
      body-class="p-0"
      title="รูปทั้งหมด"
    >
      <ImagesPreviewSlide :listData="imagePreviewList" />
    </b-modal>

    <b-modal id="modal-review-transaction" ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
      <TimelineReviewWidget :listData="listReview" />
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ImageActionCard from '../components/ImageActionCard.vue'

import TimelineReviewWidget from '@/widgets/TimelineReviewWidget.vue'
import ImagesPreviewSlide from '../components/ImagesPreviewSlide.vue'
import BoxContainerLoading from '@/widgets/BoxContainerLoading.vue'

export default {
  components: { ImageActionCard, ToastificationContent, TimelineReviewWidget, ImagesPreviewSlide, BoxContainerLoading },
  data() {
    return {
      listData: [],
      draffListData: [],
      imagePreviewList: [],
      listReview: [],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      currentItemLoading: null,
      loading: false,
      isShowMultiSelect: false,
      countImageForSend: 0,
    }
  },

  created() {
    this.fetchWallpaperReview()
  },
  methods: {
    async fetchWallpaperReview() {
      this.loading = true
      const res = await this.api.get('api/admin/admin-image-approve?status=wait&filterType=image')
      // console.log('[RES]: getImageApprove', res)
      this.listData = res
      this.loading = false
    },
    onClickPreviewImage(data) {
      // console.log('onClickPreviewImage', data)
      this.imagePreviewList = data
      this.$refs['modal-preview-images'].show()
    },
    onClickMoreTransactionReview(data) {
      // console.log('onClickMoreTransactionReview => ', data)
      this.listReview = [...data]
      this.$refs['modal-review-transaction'].show()
    },
    onClickMoreDetailsImage(data) {
      // console.log('onClickMoreDetailsImage => ', data)
      this.$router.push({ name: 'images-management-details', params: { id: data.id ?? 0, canReview: true } })
    },
    async callApiToRejectImage(data, details) {
      // console.log('[DATA]: callApiToRejectImage =>', data, details)
      const { id } = data
      this.currentItemLoading = id
      const res = await this.api.post(
        'api/admin/admin-image-timeline/create',
        {
          topic_image_id: id,
          action_type: 'reject',
          note: details,
        },
        false,
      )
      // console.log('[RES]: callApiToRejectImage => ', res)
      await this.fetchWallpaperReview()

      if (res === 'รูปอาจถูกลบไปแล้ว') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบรูปที่ต้องการทำรายการ',
            icon: 'BellIcon',
            text: 'ไม่สามารถทำการรายต่อได้ เพราะเหมือนรูปจะถูกลบไปแล้ว',
            variant: 'danger',
          },
        })
      }
      this.currentItemLoading = null
    },
    async callApiToApproveImage(data) {
      // console.log('[DATA]: callApiToApproveImage =>', data)
      const { id } = data
      this.currentItemLoading = id
      const res = await this.api.post(
        'api/admin/admin-image-timeline/create',
        {
          topic_image_id: id,
          action_type: 'approve',
          note: '',
        },
        false,
      )
      await this.fetchWallpaperReview()
      // console.log('[RES]: callApiToApproveImage =>', res)

      if (res === 'รูปอาจถูกลบไปแล้ว') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบรูปที่ต้องการทำรายการ',
            icon: 'BellIcon',
            text: 'ไม่สามารถทำการรายต่อได้ เพราะเหมือนรูปจะถูกลบไปแล้ว',
            variant: 'danger',
          },
        })
      }

      this.currentItemLoading = null
    },
    async onClickApprove(data) {
      const resAlert = await this.$swal({
        icon: 'success',
        text: 'อนุมัติรูปวอเปเปอร์ใช่หรือไม่',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })

      const { isConfirmed } = resAlert
      if (!isConfirmed) return ''

      return this.callApiToApproveImage(data)
    },
    async onClickReject(data) {
      const res = await this.$swal({
        icon: 'error',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        html: '<div class="mb-2">ไม่อนุมัติรูปวอเปเปอร์ใช่หรือไม่</div>',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: 'ระบุรายละเอียด',
        },
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm(details) {
          return details
        },
      })

      const { value, isConfirmed } = res
      if (!isConfirmed) return ''

      if (!value || `${value}`.length < 10) {
        // console.log('โปรดกรอกรายละเอียด')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดระบุรายละเอียด',
            icon: 'BellIcon',
            text: 'โปรดระบุสาเหตุที่ไม่อนุมัติรูป โดยจะต้องมากกว่า 10 ตัวอักษร',
            variant: 'danger',
          },
        })
        return ''
      }

      return this.callApiToRejectImage(data, value)
    },
    startMultiSelect() {
      this.draffListData = [...this.listData]
      this.countImageForSend = 0
      this.isShowMultiSelect = true
    },
    cancelMultiSelect() {
      this.isShowMultiSelect = false
      this.countImageForSend = 0
      this.listData = [...this.draffListData]
      this.draffListData = []
    },
    async saveMultiSelect() {
      const imageListForSend = [...this.listData].filter(v => v.isLocalSelectForSend)

      if (imageListForSend.length === 0) {
        this.gDisplayToast(
          'ยังไม่ได้เลือกวอลเปเปอร์ที่ต้องการ',
          'จำเป็นต้องเลือกวอลเปเปอร์อย่างน้อย 1 รายการ เพื่อส่งไปยังขั้นตอนการตรวจสอบ',
          'danger',
        )
        return
      }

      const isConfirm = await this.gCheckConfirmV1('ยืนยันส่งวอลเปเปอร์ทั้งหมดไปตรวจสอบ')
      if (!isConfirm) return

      const topicImageDataIds = imageListForSend.map(v => v.id)
      this.gOpenPageLoading()
      const resp = await this.api
        .postV2('api/admin/admin-topic-images/click-view-all', { topicImageDataIds }, this)
        .catch(() => null)
      this.gClosePageLoading()
      if (resp?.code === 200) {
        this.isShowMultiSelect = false
        this.countImageForSend = 0
        this.draffListData = []
        this.gDisplayToast('ดำเนินการสำเร็จแล้ว')
        await this.fetchWallpaperReview()
      } else {
        this.gDisplayToast('ดำเนินการไม่สำเร็จ', 'โปรดลองใหม่อีกครั้ง', 'danger')
      }
    },
    selectImageForSendToChecking(imageData) {
      // console.log('selectImageForSendToChecking', imageData)
      this.listData = [...this.listData].map(v => {
        if (v.id === imageData.id) {
          if (v.isLocalSelectForSend) {
            this.countImageForSend -= 1
          } else {
            this.countImageForSend += 1
          }

          return { ...v, isLocalSelectForSend: !v.isLocalSelectForSend }
        }

        return { ...v }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container-marker {
  position: relative;

  button {
    z-index: 2;
    position: relative;
  }

  .backdrop-marker {
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.1;
    border-radius: 0.5rem;
  }
}
</style>
