<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="">
    <b-card no-body class="overflow-hidden">
      <b-overlay :show="checkLoading" rounded="sm">
        <!-- <b-card-img :src="item.image_preview" alt="image preview" class="card-image-custom" /> -->
        <b-card-img-lazy :src="item.image_preview" :alt="item.name" class="card-image-custom" />
        <app-collapse>
          <app-collapse-item title="ข้อมูลรูป">
            <b-card-text class="mb-0">ชื่อรูป :</b-card-text>
            <p class="ml-1">{{ item.name }}</p>
            <b-card-text class="mb-0">รายละเอียดรูป :</b-card-text>
            <p class="ml-1">{{ item.detail }}</p>
            <b-card-text class="d-flex align-item-center">
              จำนวนรูป : {{ item.count_image }}
              <b-button
                variant="flat-primary"
                class="btn-icon rounded-circle custom-size-btn-icon"
                @click="clickPreview"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </b-card-text>
            <b-card-text class="mb-0">ขนาดรูป :</b-card-text>
            <!-- <p class="ml-1">{{ jsonData.file.naturalWidth }}w x {{ jsonData?.file.naturalHeight }}h</p> -->
            <p class="ml-1">{{ widthHeightImage }}</p>
            <b-button variant="primary" size="sm" block @click="clickMoreDetailsImage">
              ดูเพิ่มเติม
            </b-button>
          </app-collapse-item>
          <app-collapse-item title="ข้อมูลร้านค้า">
            <b-card-text class="mb-0">ชื่อร้าน :</b-card-text>
            <p class="ml-1">{{ item.user.name }}</p>
            <b-card-text class="mb-0">รายได้ :</b-card-text>
            <p class="ml-1">{{ this.withCommas(item.user.credit_balance) }}</p>
            <b-card-text class="mb-0">ลงทะเบียนร้านค้าเมื่อ :</b-card-text>
            <p class="ml-1">{{ gFormatDate(shopCreateAt, true) }}</p>
          </app-collapse-item>
          <app-collapse-item title="ประวัติรีวิว">
            <div v-if="item.image_timeline.length > 0">
              <div class="cs-group-status-date">
                <b-badge :variant="csFormat.formatVariantStatusBadge(item.image_timeline[0].status_data)">
                  {{ csFormat.formatStatusBadge(item.image_timeline[0].status_data) }}
                </b-badge>
                <small class="text-muted"
                  >วันที่รีวิวล่าสุด : {{ gFormatDate(item.image_timeline[0].created_at) }}</small
                >
              </div>
              <p class="ml-1 mt-1">{{ item.image_timeline[0].note }}</p>
              <b-button variant="primary" size="sm" block @click="clickMoreTransactionReview">
                ดูเพิ่มเติม
              </b-button>
            </div>
            <div v-else>
              <p class="text-muted">ไม่พบประวัติการรีวิว</p>
            </div>
          </app-collapse-item>
        </app-collapse>
        <b-card-footer>
          <!-- <div class="d-flex justify-content-center mb-1" v-if="item.approved_status === 'wait'">
            <b-button-group size="sm">
              <b-button variant="success" @click="clickApprove" :disabled="currentItemLoading !== null">
                อนุมัติ
              </b-button>
              <b-button variant="danger" @click="clickReject" :disabled="currentItemLoading !== null">
                ไม่อนุมัติ
              </b-button>
            </b-button-group>
          </div> -->
          <div class="cs-group-status-date">
            <b-badge :variant="csFormat.formatVariantStatusBadge(item.approved_status)">
              {{ csFormat.formatStatusBadge(item.approved_status) }}
            </b-badge>
            <small class="text-muted">วันที่ส่งรีวิว {{ gFormatDate(reviewAt, true) }}</small>
          </div>
          <b-button
            variant="outline-primary"
            block
            size="sm"
            v-if="item.approved_status === 'approve'"
            @click="clickResendNotification"
          >
            <feather-icon icon="SendIcon" class="mr-50" />
            <span class="align-middle">ส่ง notification</span>
          </b-button>
        </b-card-footer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: { AppCollapse, AppCollapseItem },
  props: {
    item: Object,
    currentItemLoading: String,
  },
  data() {
    return {
      jsonData: null,
    }
  },
  computed: {
    reviewAt() {
      return this.item.updated_at
    },
    shopCreateAt() {
      return this.item.user.created_at
    },
    checkLoading() {
      if (!this.currentItemLoading) return false

      return this.item.id === this.currentItemLoading
    },
    widthHeightImage() {
      // console.log(this.jsonData)
      // return 'test'
      if (this?.jsonData?.file) {
        const { naturalWidth, naturalHeight } = this.jsonData.file

        return `${naturalWidth}w x ${naturalHeight}h`
      }
      return 'ไม่พบข้อมูล'
    },
  },
  created() {
    this.decodeJsonData()
  },
  methods: {
    decodeJsonData() {
      const data = JSON.parse(this.item.json_data)
      this.jsonData = data
    },
    clickApprove() {
      this.$emit('onClickApprove', this.item)
    },
    clickReject() {
      this.$emit('onClickReject', this.item)
    },
    clickPreview() {
      this.$emit('onClickPreviewImage', [...this.item.topic_image_list_datas])
    },
    clickMoreTransactionReview() {
      this.$emit('onClickMoreTransactionReview', this.item.image_timeline)
    },
    clickMoreDetailsImage() {
      this.$emit('onClickMoreDetailsImage', this.item)
    },
    clickResendNotification() {
      this.$emit('onClickResendNotification', this.item)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-image-custom {
  height: 200px;
  object-fit: scale-down;
  background-color: #47526f;
}

.card .card-footer {
  padding: 1rem;
}
.custom-size-btn-icon {
  padding: 0.2rem;
  margin-left: 0.5rem;
  svg {
    width: 12px;
  }
}

.cs-group-status-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
}
</style>
